import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { TemplateLegal } from '../templates';
import { Heading, Paragraph, List, ListItem } from '@arturpol/style-guide/src/components';
import { Section, Ruler } from '../components';
export const meta = {
  title: 'Terms & Conditions',
  type: 'legal',
  hint: 'Website Terms and Conditions of Use',
  order: 80,
  seoTitle: 'Terms & Conditions',
  seoDescription: 'The terms and conditions of use of the systemsforhumans.com website.'
};
export const _frontmatter = {};
const layoutProps = {
  meta,
  _frontmatter
};
const MDXLayout = TemplateLegal;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Heading importance={1} size={6} mdxType="Heading">Website Terms<br /> and Conditions of Use</Heading>
    <Ruler mdxType="Ruler" />
    <Section title="Website administrator" mdxType="Section">
    <Paragraph mdxType="Paragraph">The systemsforhumans.com website’s (“Website”) owner and administrator is Artur Polkowski IT Services company incorporated under the laws of the Republic of Poland, with its registered office in Murowana Goslina (61-095), 10 Brzozowa Street, Tax Identification Number (NIP): 7772997209, REGON 369779061.</Paragraph>
    </Section>
    <Section title="Terms and Conditions of Use" mdxType="Section">
    <Paragraph mdxType="Paragraph">By accessing this Website, you agree to be bound by these Website Terms and Conditions of Use (“T&amp;C”), all applicable laws and regulations and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this Website. The materials contained in this Website are protected by applicable copyright and trademark law.</Paragraph>
    <Paragraph mdxType="Paragraph">You may use the Website only for lawful purposes. It is forbidden to use the Website: in any way which may lead to a violation of applicable law; in any manner that is unlawful or dishonest, or in a manner that seeks to achieve an unlawful or unfair purpose; to send, knowingly receive, upload, use or reuse content that does not comply with the T&amp;C; to forward or provoke the shipment of any unsolicited or unauthorized advertising or promotional materials, as well as any similar forms belonging to the collective category of “spam”; to knowingly transfer any data, send or upload any material containing viruses, trojans, spyware, adware or other malicious software or similar computer codes programmed to adversely affect or threaten the functioning of any software, network or computer equipment. It is forbidden to attempt to gain unauthorized access to the server on which the Website is stored, computer or database related to the Website, as well as any unauthorized access aimed at or resulting in damage or disruption of any part of the Website, equipment, or network where the Website is stored, any software used to operate the Website or any equipment, networks or software owned by or used by third parties.</Paragraph>
    </Section>
    <Section title="Use License" mdxType="Section">
    <Paragraph mdxType="Paragraph">Artur Polkowski IT Services grants permission to temporarily download one copy of the materials on the Website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license, you may not:</Paragraph>
    <List type="ordered" mdxType="List">
        <ListItem mdxType="ListItem">modify or copy the materials;</ListItem>
        <ListItem mdxType="ListItem">use the materials for any commercial purpose, or for any public display (commercial or non-commercial);</ListItem>
        <ListItem mdxType="ListItem">attempt to decompile or reverse engineer any software contained on the Website;</ListItem>
        <ListItem mdxType="ListItem">remove any copyright or other proprietary notations from the materials;</ListItem>
        <ListItem mdxType="ListItem">transfer the materials to another entity or "mirror" the materials on any other server.</ListItem>
    </List>
    <Paragraph mdxType="Paragraph">This license shall automatically terminate if you violate any of these restrictions and may be terminated by Artur Polkowski IT Services at any time. Upon termination, your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format.</Paragraph>
    </Section>
    <Section title="Disclaimer" mdxType="Section">
    <Paragraph mdxType="Paragraph">Artur Polkowski IT Services makes no warranties and representations, expressed or implied, to the digital availability, functionality, compatibility, visibility, and/or comprehensibility of the Website. Artur Polkowski IT Services makes no guarantee that the Website or any of its contents will always be available or access will not be impeded. Artur Polkowski IT Services holds no liability for any temporary or permanent unavailability or inaccessibility of the Website, in whole or in any part, for any reason and at any time. The materials on the Website are provided "as is". Artur Polkowski IT Services makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties, including without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights. Artur Polkowski IT Services does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on the Website or otherwise relating to such materials or on any sites linked to this site.</Paragraph>
    <Paragraph mdxType="Paragraph">Any information or statements included on the Website shall not constitute a binding offer and cannot be construed as such.</Paragraph>
    </Section>
    <Section title="Limitations" mdxType="Section">
    <Paragraph mdxType="Paragraph">In no event shall Artur Polkowski IT Services or its suppliers be liable for any damages (including, without limitation, damages for loss of data, profit, anticipated savings, violation of business continuity, loss of reputation, claims of third parties, damage caused by delay or moral damage) arising out of the use or inability to use the materials on the Website. In particular, Artur Polkowski IT Services is not liable for damages caused by viruses and other software, and/or that results from accessing or downloading materials that the Website contains. If you decide to download materials from the Website, you do so at your own risk.</Paragraph>
    <Paragraph mdxType="Paragraph">Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.</Paragraph>
    </Section>
    <Section title="Revision &amp; Errata" mdxType="Section">
    <Paragraph mdxType="Paragraph">The materials appearing on the Website could include technical, typographical, or photographic errors. Artur Polkowski IT Services does not warrant that any of the materials on the Website are accurate, complete, or up-to-date. Artur Polkowski IT Services is entitled to suspend, withdraw, cease operation, or modify all or some elements of the Website at any time without notice. Artur Polkowski IT Services does not, however, make any commitment to update the materials.</Paragraph>
    </Section>
    <Section title="Links" mdxType="Section">
    <Paragraph mdxType="Paragraph">The Website may contain third-party materials or links to other websites. Links to other websites are provided on the Website for your information and convenience only. Artur Polkowski IT Services has no control over these websites, does not check, oversee, review, approve, or endorse its contents. Artur Polkowski IT Services is not responsible for the availability of these websites, their contents nor for any damage or harm that might be inflicted as a result of accessing those websites. Use of any such linked website is at the user's own risk.</Paragraph>
    </Section>
    <Section title="Modification" mdxType="Section">
    <Paragraph mdxType="Paragraph">Artur Polkowski IT Services may revise, modify, or amend the T&amp;C at any time without notice. By using this Website you agree to be bound by the current version of the T&amp;C.</Paragraph>
    </Section>
    <Section title="Breach, Governing Law" mdxType="Section">
    <Paragraph mdxType="Paragraph">If Artur Polkowski IT Services becomes aware of a breach of the T&amp;C, Artur Polkowski IT Services may immediately implement corrective actions, including disabling access to the Website as well as removing any information, data, and content provided by the user on the Website at any time, to any extent and without notice.</Paragraph>
    <Paragraph mdxType="Paragraph">Any claims or dispute relating to the use of a Website or a breach of this T&amp;C shall be governed by the laws of the Republic of Poland without regard to its conflict of law provisions. Any claims and disputes shall be settled by the competent Polish common court.</Paragraph>
    </Section>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      